@font-face {
  font-family: 'Digestive';
  src: url('../assets/fonts/Digestive_v0.9-four_web.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/Digestive_v0.9-four_web.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../assets/fonts/Digestive_v0.9-four_web.woff') format('woff');
}

@font-face {
  font-family: 'Ogg-Italic';
  src: url('../assets/fonts/Ogg-Italic.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/Ogg-Italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../assets/fonts/Ogg-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Ogg-Roman';
  src: url('../assets/fonts/Ogg-Roman.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/Ogg-Roman.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../assets/fonts/Ogg-Roman.woff') format('woff');
}

@font-face {
  font-family: 'Chapter';
  src: url('../assets/fonts/Chapter-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/Chapter-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../assets/fonts/Chapter-Regular.woff') format('woff');
}