body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Ogg-Roman";
  overscroll-behavior: none;
  background-color: white;
  color: #161616;
}

html {
  box-sizing: border-box;
  font-family: "Chapter";
  overscroll-behavior: none;

}
*, *:before, *:after {
  box-sizing: inherit;
}

h2 {
  font-size: 25px;
  font-family: "Ogg-Roman"
}

::-webkit-scrollbar {
  display: none;
}

#loop {
  position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  box-sizing: content-box;
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -webkit-transform:translate3d(0,0,0);
}